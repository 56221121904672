<template>
  <section id="Contacts" data-menu-title="Contacts">
    <div class="agent-container" v-if="agent.name">
      <div class="row">
        <div class="col-lg col-md info">
          <div class="fio container">
            <h2>
              <div class="name">{{ agent.name }}</div>
              <div class="surname">{{ agent.surname }}</div>
              <div class="advecs">ГК «АДВЕКС. НЕДВИЖИМОСТЬ», САНКТ-ПЕТЕРБУРГ</div>
            </h2>
          </div>
          <h1 class="title text-right">Контакты агента</h1>

          <div
            class="contacts d-flex
        container justify-content-end
        flex-wrap"
          >
            <div class="d-flex flex-column align-self-start mr-lg-1 mb-1">
              <a
                v-if="agent.phone"
                role="button"
                target="_blank"
                :href="'tel:+7' + agent.phone"
                class="btn btn-light mx-1 mb-1 longbtn"
              >
                +7{{ agent.phone }}
              </a>

              <a
                role="button"
                v-if="agent.sys_user_email2"
                target="_new"
                :href="'mailto:' + agent.sys_user_email2"
                class="longbtn btn btn-light mx-1 mt-1 mb-1"
              >
                {{ agent.sys_user_email2 }}
              </a>

              <a
                role="button"
                v-if="agent.agency"
                target="_new"
                :href="this.agent.agency.url"
                class="longbtn btn btn-light mx-1 mt-1 mb-1"
              >
                {{ agent.agency.name }}
              </a>
            </div>

            <div class="d-flex flex-column justify-content-end align-self-start">
              <button
                v-if="agent.reviews"
                type="button"
                class="btn btn-primary mx-1 mb-2 longbtnmob"
                data-toggle="modal"
                data-target="#reviewsModal"
                v-on:click="stopscroll"
              >
                Отзывы
              </button>

              <button
                v-if="agent.objects"
                type="button"
                class="btn btn-primary mx-1 mb-2 longbtnmob"
                data-toggle="modal"
                data-target="#objectsModal"
                v-on:click="stopscroll"
              >
                Объекты
              </button>

              <button
                v-if="agent.certs"
                type="button"
                class="btn mx-1 mb-2 longbtnmob"
                data-toggle="modal"
                data-target="#certsModal"
                v-on:click="stopscroll"
              >
                Сертификаты
              </button>
            </div>
          </div>

          <div v-if="agent.text_about" class="about">
            <p>{{ agent.text_about }}</p>
          </div>
        </div>

        <div class="col-lg-4">
          <img v-if="agent.photo" :src="agent.photo" class="mx-auto d-block photo img-fluid pb-3" />
          <img v-else src="../assets/nophoto.jpg" class="mx-auto d-block photo img-fluid pb-3" />

          <div class="socialbuttons d-flex flex-wrap">
            <a
              v-if="agent.social_network_vk"
              role=""
              :href="agent.social_network_vk"
              target="_blank"
              class="soclink"
            >
              <font-awesome-icon :icon="['fab', 'vk']" class="soclogo vk" />
            </a>

            <a
              v-if="agent.social_network_youtube"
              target="_blank"
              :href="agent.social_network_youtube"
              class="soclink"
            >
              <font-awesome-icon :icon="['fab', 'youtube']" class="soclogo youtube" />
            </a>

            <a
              v-if="agent.social_network_ok"
              target="_blank"
              :href="agent.social_network_ok"
              class="soclink"
            >
              <font-awesome-icon :icon="['fab', 'odnoklassniki']" class="soclogo odnoklassniki" />
            </a>

            <a
              v-if="agent.messenger_telegram"
              target="_blank"
              :href="agent.messenger_telegram"
              class="soclink"
            >
              <font-awesome-icon :icon="['fab', 'telegram']" class="soclogo telegram" />
            </a>

            <a
              v-if="agent.messenger_skype"
              role=""
              :href="'skype:' + agent.messenger_skype + '?chat'"
              target="_blank"
              class="soclink"
            >
              <font-awesome-icon :icon="['fab', 'skype']" class="soclogo skype" />
            </a>

            <a
              v-if="agent.phone"
              role=""
              :href="'sms://+7' + agent.phone"
              target="_blank"
              class="soclink"
            >
              <font-awesome-icon :icon="['fa', 'comment']" class="soclogo imessage" />
            </a>

            <a
              v-if="agent.messenger_tamtam"
              role=""
              :href="agent.messenger_tamtam"
              target="_blank"
              class="soclink"
            >
              <svg
                class="soclogo tamtam"
                e-hjmiv0=""
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26 26"
              >
                <path
                  e-hjmiv0=""
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M10.476 22.401l-3.263 2.201c-.331.224-.6.08-.601-.318l-.007-3.515a11.04 11.04 0 0 1-4.873-9.156C1.719 5.516 6.616.566 12.672.553c6.052-.01 10.97 4.92 10.984 11.016.012 6.096-4.887 11.046-10.94 11.058a10.89 10.89 0 0 1-2.24-.226m7.229-10.475l-10.02.02c.005 2.786 2.252 5.04 5.02 5.035 2.766-.006 5.005-2.269 5-5.055"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="agent-container noid" v-else>
      <h1 class="title text-right">Контакты агента</h1>
      <h3>
        Телефон контакт-центра <a href="tel:+78123225200">+7 (812) 322-52-00</a><br />
        <small class="text-muted">
          Мы принимаем звонки с 09:00 до 21:00 по будням и с 10:00 до 17:00 по выходным.
        </small>
      </h3>
      <br />
      <h3>
        В любое время вы можете оставить заявку на нашем сайте:
        <a target="_blank" href="https://www.advecs.com">https://www.advecs.com</a>
      </h3>
      <br />
      <h3>
        Адреса и телефоны офисов:
        <a href="https://www.advecs.com/offices" target="_blank">
          https://www.advecs.com/offices
        </a>
      </h3>
      <br />
      <h3>
        Найти агента:
        <a href="https://www.advecs.com/agents" target="_blank">
          https://www.advecs.com/agents
        </a>
      </h3>
    </div>
  </section>
</template>

<script>
import Reveal from "reveal.js";

export default {
  name: "AgentsCard",
  metaInfo() {
    return {
      title: this.sharetitle,
      meta: [
        { property: "og:title", content: this.sharetitle },
        { property: "og:site_name", content: this.sharetitle },
        { property: "og:type", content: "website" },
        { property: "og:url", content: this.shareurl },
        { property: "og:image", content: this.agent.photo }
        // { property: 'og:description', content: this.article.description },
      ]
    };
  },
  props: ["agent"],
  methods: {
    stopscroll() {
      Reveal.configure({ mouseWheel: false });
    }
  },
  computed: {
    sharetitle() {
      if (this.agent.name && this.agent.surname) {
        const title = `${this.agent.name} ${this.agent.surname} – агент ГК «Адвекс. Недвижимость»`;
        return title;
      }
      const title = "ГК «Адвекс. Недвижимость»";
      return title;
    },
    shareurl() {
      if (this.agent.id) {
        const shareurl = `https://card.advecs.com/${this.agent.id}`;
        return shareurl;
      }
      const shareurl = "https://card.advecs.com";
      return shareurl;
    },
    sharepic() {
      return true;
    }
  }
};
</script>
<style scoped>
a {
  color: #005ead;
}
.btn {
  width: 210px;
  height: 30px;
  background-color: #355ea9;
  border: unset;
  color: white;
  font-size: 16px;
  text-align: right;
  padding-top: 4px !important;
  padding-right: 8px !important;
}
.longbtn {
  width: 270px;
}
.fio {
  float: right;
  text-align: right;
  margin-bottom: 20px;
  margin-top: -10px;
}
.title {
  display: none;
}
.name {
  font-family: Gilroy-Thin, sans-serif;
  font-size: 49px;
  letter-spacing: -4px;
  margin-bottom: -17px;
}

.surname {
  font-family: Gilroy-Bold, sans-serif;
  font-size: 49px;
}

.advecs {
  font-family: Gilroy-Regular;
  font-size: 22px;
  letter-spacing: -0.5px;
}

.photo {
  width: 324px;
  height: 493px;
  object-fit: cover;
}

.agent-container {
  padding-left: 85px;
  max-width: 1017px;
  margin-top: 50px;
  font-family: "Gilroy-Medium", sans-serif;
}

.noid {
  margin-top: 83px;
}

.soclink {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 5px;
}

.agent-col {
}
.socialbuttons {
}

.soclogo {
  margin: 0 auto;
  display: block;
  vertical-align: middle;
  color: white;
  background-color: blue;
  padding: 5px;
  height: 30px;
  width: 33px;
  border-radius: 5px;
  shape-rendering: auto;
}
.vk {
  background-color: #2787f5;
}
.telegram {
  background-color: #0088cc;
}

.youtube {
  background-color: #ff0000;
}
.tiktok {
  background-color: #010101;
  color: white;
}
.odnoklassniki {
  background-color: #ed812b;
}
.viber {
  background-color: #7360f2;
}
.skype {
  background-color: #00aff0;
}
.imessage {
  background-color: #2dd223;
}
.about {
  font-size: 16px !important;
  margin-top: 35px;
}
.about p {
  white-space: pre-wrap;
}
.tamtam {
  background-color: #6678f7;
}

/* .btn {
  border-radius: unset !important;
}
.soclogo {
  border-radius: unset !important;
} */
@media screen and (max-width: 500px) {
  .agent-container {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .fio {
    display: none;
    margin-bottom: 10px;
  }
  .noid {
    margin-top: 35px;
  }
  .noid .title {
    margin-top: 0px;
  }
  .title {
    display: block;
    margin-bottom: 35px;
  }
  .advecs {
    font-size: 13px;
  }
  .longbtnmob {
    width: 270px;
  }
  .contacts {
    justify-content: center !important;
  }
  .contacts a {
    text-align: center;
  }
  .contacts button {
    text-align: center;
  }
  .about {
    padding-left: 0 !important;
    order: -1;
  }
  .photo {
    display: none !important;
  }
}
</style>
